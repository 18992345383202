import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-instructions',
  templateUrl: './modal-instructions.component.html',
  styleUrls: ['./modal-instructions.component.scss'],
})
export class ModalInstructionsComponent implements OnInit {
  @Input() set data(data: any) {
    if (data) {
      this._data = { ...data };
      this.msjRedemption = this._data.message;
      this._data.buttons = this._data.hasButtonDismiss
        ? [
            {
              label: this._data.labelButtonDismiss,
              color: 'primary',
              handler: () => {
                this.dismiss();
              },
            },
            ...data.buttons,
          ]
        : [...data.buttons];
    }
  }
  _data;
  msjRedemption;
  iconRedemption;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    console.log(this._data);
  }

  async dismiss() {
    await this.modalController.dismiss();
  }
}
