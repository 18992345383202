import { Component, OnInit, Input } from "@angular/core";
//Services
import { CameraService } from "src/app/services/camera-service/camera.service";
import { FirebaseStorageService } from "src/app/services/firebase-storage/firebase-storage.service";
import { UserService } from "src/app/services/user/user.service";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { AlertController } from '@ionic/angular';

export const KEY_AVATAR_PROFILE = "KEY_AVATAR_PROFILE";
@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
  @Input() shape: string = "false";
  @Input() edit: string = "true";
  @Input() size: "small" | "medium" | "large" = "medium";
  @Input() shadow: string = "true";
  img: any = "../../../assets/img/default-user.jpg";
  LOADER_ID = "change_picture";
  hideGamification = false;

  constructor(
    private cameraService: CameraService,
    private firebaseStorageService: FirebaseStorageService,
    private userService: UserService,
    public remoteConfig: RemoteConfigService,
    public alertController: AlertController
  ) {}

  ngOnInit() {
    this.getPicture();
    this.getHideGamification();
  }

  async takePicture() {
    try {
      const picture = await this.cameraService.takePicture();
      const urlImage = await this.firebaseStorageService.saveOnStorage(picture);
      if(urlImage && !urlImage['status']){
        this.presentAlert();
        return false;
      }
      await this.userService.savePictureOnfirestore(urlImage['url']);
      await this.getPicture();

    } catch (err) {
      console.log(err);
    }
  }

  async getPicture() {
    this.userService.getUserInfo().subscribe(
      (res: any) => {
        if (res && res.urlAvatar) {
          this.img = res.urlAvatar;
        }
      },
      (err) => {
        console.log("entro aca");
      }
    );
  }

  async getHideGamification() {
    return this.remoteConfig.getHideGamification().subscribe((res) => {
      this.hideGamification = res;
    });
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      subHeader: 'Atención',
      message: 'El tamaño de la imagen no puede superar las 15mb.',
      buttons: ['Ok']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
}
