import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalInstructionsComponent } from './modal-instructions.component';

@Injectable({
  providedIn: 'root',
})
export class ModalInstructionsService {
  IsOpen = false;
  constructor(private modalController: ModalController) {}

  async OpenModal(_modalData, _height?, _modalComponent?) {
    const el = document.getElementsByTagName('ion-modal');
    if (el.length > 0) {
      return;
    }
    if (!this.IsOpen) {
      const modal = await this.modalController.create({
        component: _modalComponent
          ? _modalComponent
          : ModalInstructionsComponent,
        id: 'modalComponent-modal',
        componentProps: {
          data: _modalData,
          onClose: async (data) => {
            await modal.dismiss({ dismissed: true });
          },
        },
        cssClass: 'height-modal-information',
        backdropDismiss: false,
        swipeToClose: false,
      });

      modal.onDidDismiss().then(() => {
        this.IsOpen = false;
        // this.closeAllModalsRedemption();
      });
      return await modal.present().then(() => {
        this.IsOpen = true;
        const el = document.getElementsByTagName('ion-modal');
        for (let i = 0; i < el.length; i++) {
          const element = el[i];
          if (element.id == 'modalComponent-modal') {
            element.style.setProperty('--height', _height ? _height : '50vh');
          }
        }
      });
    }
  }
}
